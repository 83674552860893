import { tns } from '../node_modules/tiny-slider/src/tiny-slider'
import 'accordion-js'
import MicroModal from 'micromodal'
import fitvids from 'fitvids'
import Cookies from 'js-cookie'
import baguetteBox from 'baguettebox.js'
// @ts-ignore
import Accordion from 'accordion-js'

export const setupAccordion = () => {
	try {
		console.log('Wiring up accordion...')
		const hasAccordion = document.querySelectorAll('.ac').length

		if (hasAccordion > 0) {
			const accordions = document.querySelectorAll('.accordion-container')
			console.log('has accordions: ', accordions)

			accordions.forEach(element => {
				new Accordion(element as HTMLElement)
			})
		}
	} catch (err) {
		console.error('Accordion setup error: ', err)
	}
}

export const setupSlider = () => {
	try {
		console.log('Wiring up slider...')

		if (document.body.classList.contains('home')) {
			var homeSlider = tns({
				container: '.home-slider',
				items: 1,
				autoplay: true,
				mode: 'gallery',
				controls: false,
				autoplayTimeout: 1500,
				speed: 1000,
				nav: false,
				autoplayButtonOutput: false,
			})
		}

		const hasPhotoRow = document.querySelectorAll('.photo-row-slider').length

		if (hasPhotoRow > 0) {
			var photoRowSlider = tns({
				container: '.photo-row-slider',
				items: 1,
				autoplay: true,
				mode: 'carousel',
				controls: false,
				autoplayTimeout: 3000,
				speed: 1000,
				nav: false,
				autoplayButtonOutput: false,
				edgePadding: 0,
				gutter: 0,
				disable: false,
				//autoHeight: true,
				responsive: {
					850: {
						disable: true,
					},
				},
			})
		}
	} catch (err) {
		console.error('Slider setup error: ', err)
	}
}

export const setupGalery = () => {
	try {
		console.log('Wiring up baguette box...')
		baguetteBox.run('.gallery')
	} catch (err) {
		console.error('Gallery setup error: ', err)
	}
}

export const setupFitVids = () => {
	try {
		console.log('Wiring up fitvids...')
		fitvids('.video')
	} catch (err) {
		console.error('Fitvids setup error: ', err)
	}
}

export const setupModals = () => {
	try {
		console.log('Wiring up micro modal...')
		MicroModal.init({
			openTrigger: 'data-custom-open',
			closeTrigger: 'data-custom-close',
			awaitOpenAnimation: true,
			awaitCloseAnimation: true,
		})

		const modalTitles = document.querySelectorAll('.modal__title')
		modalTitles?.forEach(title => {
			const htmlTitle = title as HTMLElement
			if (htmlTitle.textContent && htmlTitle.textContent.length > 40) {
				htmlTitle.classList.add('long-title')
			}
		})
	} catch (err) {
		console.error('Modal setup error: ', err)
	}
}

export const setupCookies = () => {
	try {
		console.log('Wiring up cookies...')
		if (typeof window !== 'undefined') {
			window.Cookies = Cookies
		}

		// if (Cookies.get('signup') === undefined) {
		// 	console.log('No cookies')
		// 	setTimeout(function () {
		// 		MicroModal.show('signup')
		// 	}, 10000)
		// }

		// if (Cookies.get('signup') === 'seen') {
		// 	console.log('We have cookies')
		// }

		// if (Cookies.get('signup') === undefined) {
		// 	setTimeout(function () {
		// 		Cookies.set('signup', 'seen', { expires: 60, path: '/' })
		// 		console.log('Signup cookie created')
		// 	}, 10500)
		// }

		if (Cookies.get('popup') === undefined) {
			console.log('No cookies')
			setTimeout(function () {
				MicroModal.show('popup')
			}, 2500)
		}

		if (Cookies.get('popup') === 'seen') {
			console.log('We have cookies')
		}

		if (Cookies.get('popup') === undefined) {
			setTimeout(function () {
				Cookies.set('popup', 'seen', { expires: 3, path: '/' })
				console.log('popup cookie created')
			}, 2750)
		}
	} catch (err) {
		console.error('Cookie setup error: ', err)
	}
}

export const setupMenu = () => {
	try {
		console.log('Wiring up menu...')
	} catch (err) {
		console.error('Menu setup error: ', err)
	}
}

export const setupClasses = () => {
	try {
		console.log('Wiring up classes...')
		const soldOutToggleWrap = document.getElementById('sold-out-toggle-wrap')
		const soldOutToggle = document.getElementById('sold-out-toggle')
		const classes = document.getElementById('classes')
		const soldOutClasses = document.querySelectorAll('.__sold-out') as NodeListOf<HTMLElement>

		soldOutToggleWrap && soldOutToggle && classes
			? soldOutToggle.addEventListener('click', () => {
					soldOutToggleWrap.classList.toggle('hidden')
					console.log('clicked')
					classes.classList.toggle('hide-sold-out')
					soldOutClasses.forEach(function (child) {
						child.classList.toggle('hide')
						if (child.classList.contains('hide')) {
							setTimeout(() => {
								child.style.display = 'none' // Apply display: none; after opacity transition
							}, 500) // Match the duration of the opacity transition
						} else {
							child.style.display = 'block' // Reset display if showing again
						}
					})
			  })
			: null
	} catch (err) {
		console.error('Classes setup error: ', err)
	}
}
