import {
	setupCookies,
	setupFitVids,
	setupGalery,
	setupSlider,
	setupMenu,
	setupModals,
	setupClasses,
	setupAccordion,
} from './init.helpers'
import { setupCheckoutExtras, setupNavExtras } from './setup.helpers'

const siteInit = () => {
	//Init libs
	document.addEventListener('DOMContentLoaded', () => {
		console.log('initiated')
		setupAccordion()
		setupMenu()
		setupSlider()
		setupModals()
		setupCookies()
		setupFitVids()
		setupGalery()
		setupClasses()
		setupCheckoutExtras()
		setupNavExtras()
	})
}
siteInit()
