// export const setupTheThing = () => {
// 	try {
// 		console.log('Wiring up the thing...')
// 		//do stuff
// 	} catch (err) {
// 		console.error('The thing setup error: ', err)
// 	}
// }
import jQuery from 'jquery'

document.addEventListener('DOMContentLoaded', function (event) {
	//class fade in
	// let classArray = document.querySelectorAll('.class')
	// let profileArray = document.querySelectorAll('.profile-card')
	// window.addEventListener('scroll', fadeIn)
	// window.scrollTo(1, 1)
	// // window.scrollTo(0, 0)
	// function fadeIn() {
	// 	for (var i = 0; i < classArray.length; i++) {
	// 		var elem = classArray[i]
	// 		var distInView = elem.getBoundingClientRect().top - window.innerHeight + 20
	// 		if (distInView <= 0) {
	// 			elem.classList.add('inView')
	// 		} else {
	// 			elem.classList.remove('inView')
	// 		}
	// 	}
	// 	for (var i = 0; i < profileArray.length; i++) {
	// 		var elem = profileArray[i]
	// 		var distInView = elem.getBoundingClientRect().top - window.innerHeight + 20
	// 		if (distInView <= 0) {
	// 			elem.classList.add('inView')
	// 		} else {
	// 			elem.classList.remove('inView')
	// 		}
	// 	}
	// }
	//prevent image drag and contextmenu
	let images = document.querySelectorAll('img')
	images.forEach(function (image) {
		image.setAttribute('draggable', 'false')
		image.addEventListener('dragstart', event => event.preventDefault())
		image.addEventListener('contextmenu', function (e) {
			e.preventDefault()
		})
	})
})

export const setupCheckoutExtras = () => {
	try {
		console.log('Wiring up checkout extras...')
		//do stuff
		jQuery('li.c-step:nth-child(3)').text('Billing Info')
		//$('li.c-step:nth-child(3)').remove();
		//$('li.c-step:last-child').remove();

		// jQuery('#c-choose-payment-form > div:nth-child(2) > div > label').before(
		// 	'<i class="fa-brands fa-paypal method"></i>'
		// )
		// jQuery('#c-choose-payment-form > div:nth-child(3) > div > label').before(
		// 	'<i class="fa-solid fa-credit-card method"></i>'
		// )
		const quantityInputs = document.querySelectorAll('.c-cart-item-quantity-input')
		const updateCartButton: HTMLElement = document.querySelector('.c-button') as HTMLElement

		quantityInputs.forEach(function (quantityInput) {
			quantityInput.addEventListener('change', function () {
				updateCartButton?.click()
				console.log('update cart clicked')
			})
		})
	} catch (err) {
		console.error('Checkout extras setup error: ', err)
	}
}

export const setupNavExtras = () => {
	try {
		console.log('Wiring up nav extras...')
		// const navNewsletterBtn = document.getElementById('nav-newsletter-btn')
		// const navNewsletter = document.getElementById('nav-newsletter')
		// const navNewsletterForm = document.getElementById('mc-embedded-subscribe-form')
		// const mcSubmitBtn = document.getElementById('mc-submit-btn')
		// const mcSuccessMessage = document.getElementById('mce-success-response')

		// const newsletterOverlay = document.getElementById('newsletter-overlay')

		// console.log('mc-embedded-subscribe-form: ', navNewsletterForm)

		// navNewsletterForm?.addEventListener('submit', function (event) {
		// 	console.log('submitting...')
		// 	if (mcSuccessMessage && window.getComputedStyle(mcSuccessMessage).display !== 'none') {
		// 		navNewsletterForm.style.display = 'none'
		// 		console.log('submitted and hidden')
		// 	} else {
		// 		console.log('not submitted')
		// 		return
		// 	}
		// })

		// mcSubmitBtn?.addEventListener('click', function (e) {
		// 	console.log('mcSubmit Btn clicked')
		// 	setTimeout(function () {
		// 		console.log('mcSubmit Btn timeout')
		// 		navNewsletterForm && navNewsletterForm.classList.add('__hidden')
		// 	}, 500)
		// })

		// navNewsletterBtn?.addEventListener('click', function (e) {
		// 	e.preventDefault()
		// 	console.log('nav newsletter btn clicked')
		// 	navNewsletter?.classList.add('__active')
		// 	newsletterOverlay?.classList.toggle('show')
		// })

		// newsletterOverlay?.addEventListener('click', function (e) {
		// 	e.preventDefault()
		// 	console.log('nav newsletter btn clicked')
		// 	navNewsletter?.classList.remove('__active')
		// 	newsletterOverlay?.classList.toggle('show')
		// })
	} catch (err) {
		console.error('Nav extras setup error: ', err)
	}
}

export const dontSetupNavExtras = () => {
	try {
		console.log('Wiring up nav extras...')
		//do stuff

		jQuery('.l1:has(.nav-submenu)').on('click', function (e) {
			e.preventDefault()
			console.log('clikd...')

			if (!jQuery(this).hasClass('open')) {
				// hide any open menus and remove all other classes
				jQuery('.nav-submenu').slideUp(350)
				jQuery('.l1:has(.nav-submenu)').removeClass('open')

				// open our new menu and add the open class
				jQuery(this).next('ul').slideDown(350)
				jQuery(this).addClass('open')
			} else if (jQuery(this).hasClass('open')) {
				jQuery(this).removeClass('open')
				jQuery(this).next('.nav-submenu').slideUp(350)
			}
		})
	} catch (err) {
		console.error('Nav extras setup error: ', err)
	}
}
